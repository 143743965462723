<template>
  <div class="modal-material">
    <b-modal id="modal-video" centered hide-footer @hidden="resetModal">
      <template #modal-title>
        <div class="modal-material__title">
          {{ isModalTypeCreated ? $t('header.createVideo') : $t('header.editVideo') }}
        </div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <div
          class="selected-material"
          :class="{ 'selected-material--creation': isModalTypeCreated }"
        >
          <div class="selected-material__info info">
            <div class="info__title">
              {{ modalTitle }}
            </div>
            <div class="info__subtitle">
              {{
                isModalTypeCreated
                  ? $t('supportText.youtubeAndVimeoSupported')
                  : $t('supportText.canChangeTitleDescriptionLink')
              }}
            </div>
          </div>
          <div class="selected-material__action meta">
            <div class="meta__video-link">
              <LmsInput
                id="material-video-link"
                :label="$t('placeholders.enterVideoLink')"
                :value.sync="link"
                @change="validateLink"
                :class="{
                  invalid: $v.videoLink.$dirty && $v.videoLink.$anyError,
                  correct: $v.videoLink.$dirty && !$v.videoLink.$anyError,
                }"
              />
            </div>
            <div v-if="$v.videoLink.$dirty && !$v.videoLink.required" class="helper-text">
              {{ $t('errorMessages.pleaseFill') }}
            </div>
            <div v-else-if="$v.videoLink.$dirty && !$v.videoLink.existingLink" class="helper-text">
              {{ $t('errorMessages.provideValidLink') }}
            </div>
            <div class="meta__title">
              <LmsInput
                id="material-title"
                :label="$t('placeholders.enterTitleForMaterial')"
                :value.sync="title"
                @change="() => $v.title.$touch()"
                :class="{
                  invalid: $v.title.$dirty && !$v.title.required,
                  correct: $v.title.$dirty && !$v.title.$anyError,
                }"
              />
            </div>
            <div v-if="$v.title.$dirty && !$v.title.required" class="helper-text">
              {{ $t('errorMessages.pleaseFill') }}
            </div>
            <div class="meta__description">
              <LmsInput
                id="material-description"
                :label="$t('placeholders.enterDescriptionForMaterial')"
                :value.sync="description"
                @change="() => $v.description.$touch()"
                :class="{
                  correct: $v.description.$dirty && !$v.description.$anyError,
                }"
              />
            </div>
            <div class="meta__score">
              <div class="score-container">
                <span class="score-title">{{ $t('label.scorePerView') }}</span>
                <LmsInput
                  id="material-score"
                  type="number"
                  placeholder="max 999"
                  :value.sync="max_score"
                  :class="{
                    'error-message--activate':
                      $v.max_score.$dirty && !$v.max_score.maximumNotExceeded,
                  }"
                />
                <div
                  class="error-message"
                  :class="{
                    'error-message--activate':
                      $v.max_score.$dirty && !$v.max_score.maximumNotExceeded,
                  }"
                >
                  <p>{{ $t('label.maximum') }} 999</p>
                </div>
              </div>
            </div>
            <div class="meta__action">
              <AddButton
                :title="
                  isModalTypeCreated ? $t('buttonLabels.addVideo') : $t('buttonLabels.saveChanges')
                "
                :isDisabled="loading"
                @click="saveVideoMaterial"
              />
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import urlParser from 'js-video-url-parser';
import LmsInput from '@/components/LmsInput.vue';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import AddButton from '@/components/Buttons/AddButton.vue';

export default {
  name: 'ModalVideo',
  components: {
    LmsInput,
    SmallCloseButton,
    AddButton,
  },
  data: () => ({
    videoLink: '',
    link: '',
    title: '',
    description: '',
    max_score: null,
    loading: false,
  }),
  validations: {
    title: {
      required,
    },
    videoLink: {
      required,
      existingLink: (link) => {
        if (link === '') return true;
        const parsed = urlParser.parse(link);
        if (!parsed) {
          try {
            const url = new URL(link);
            if (url.hostname === 'rutube.ru') {
              return true;
            }
          } catch (_) {
            return false;
          }
        }

        return ['youtube', 'vimeo'].includes(parsed?.provider);
      },
    },
    description: {
      alwaysValid: () => true,
    },
    max_score: {
      maximumNotExceeded(int) {
        return int < 1000;
      },
    },
  },
  computed: {
    ...mapGetters({ isModalTypeCreated: 'modalType/getIsModalTypeCreated' }),
    ...mapGetters({ currentVideoMaterial: 'courseContentTeacher/selectedContent' }),
    ...mapGetters('courseContentTeacher', [
      'contentLevel',
      'groupIdOfLevelOne',
      'groupIdOfLevelTwo',
    ]),
    isDifferentFromInitialValues() {
      return (
        this.title !== this.currentVideoMaterial.name
        || this.description !== this.currentVideoMaterial.description
        || this.videoLink !== this.currentVideoMaterial.meta.url
        || this.max_score !== this.currentVideoMaterial.max_score
      );
    },
    parsedLink() {
      return urlParser.parse(this.link);
    },
    titleVideoEditing() {
      if (this.link.includes('rutube')) {
        return this.$t('header.rutubeLink');
      }
      if (this.parsedLink?.provider === 'youtube') {
        return this.$t('header.videoYoutube');
      }
      if (this.parsedLink?.provider === 'vimeo') {
        return this.$t('header.videoVimeo');
      }
      return this.$t('buttonLabels.video');
    },
    modalTitle() {
      return this.isModalTypeCreated ? this.$t('header.addLinkToVideo') : this.titleVideoEditing;
    },
  },
  methods: {
    ...mapActions('courseContentTeacher', ['updateActivity', 'selectContentId', 'createActivity']),
    ...mapActions({ setToaster: 'toaster/setToaster' }),
    ...mapActions('modalType', ['setModalTypeIsCreated']),
    async saveVideoMaterial() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      if (this.isModalTypeCreated) {
        await this.createVideoMaterial();
      } else {
        await this.updateVideoMaterial();
      }
      this.loading = false;
      this.$bvModal.hide('modal-video');
    },
    createVideoMaterial() {
      // eslint-disable-next-line no-nested-ternary
      const parentId = this.contentLevel === 0
        ? this.$route.params.courseId
        : this.contentLevel === 1
          ? this.groupIdOfLevelOne
          : this.groupIdOfLevelTwo;

      return this.createActivity({
        activityType: 'video',
        title: this.title,
        description: this.description,
        max_score: this.max_score ? this.max_score : 0,
        meta: { url: this.videoLink },
        parentId,
      })
        .then(() => {
          this.$bvModal.hide('modal-video');
        })
        .catch(() => {
          const toast = {
            title: this.$t('errorMessages.somethingWentWrong'),
            body: this.$t('errorMessages.tryAgain'),
          };
          this.setToaster({
            type: 'toast-danger',
            toast,
          });
        });
    },
    updateVideoMaterial() {
      if (!this.isDifferentFromInitialValues) {
        return;
      }
      return this.updateActivity({
        id: this.currentVideoMaterial._id,
        activity: {
          type: 'video',
          name: this.title,
          description: this.description,
          meta: { url: this.link },
          max_score: this.max_score ? this.max_score : 0,
        },
      });
    },
    resetModal() {
      this.$nextTick(() => {
        this.setModalTypeIsCreated(false);
        this.selectContentId(null);
        this.title = '';
        this.description = '';
        this.link = '';
        this.videoLink = '';
        this.max_score = null;
        if (this.$v) {
          this.$v.$reset();
        }
      });
      this.$emit('unmountModal');
    },
    validateLink(link) {
      this.videoLink = link;
      this.$v.videoLink.$touch();
    },
  },
  watch: {
    currentVideoMaterial: {
      handler(video) {
        this.title = video?.name ?? '';
        this.description = video?.description ?? '';
        this.max_score = video?.max_score ?? 0;
        this.link = video?.meta?.url ?? '';
        this.videoLink = video?.meta?.url ?? '';
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
